import React, { createContext, useContext, useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

interface NetworkStatusContextProps {
  isOnline: boolean;
}

const initialContext = {
  isOnline: false,
};

const NetworkStatusContext =
  createContext<NetworkStatusContextProps>(initialContext);

interface IAuthenticationProvider {
  children: JSX.Element;
}

export const NetworkStatusProvider = (props: IAuthenticationProvider) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOnline }}>
      {props.children}
    </NetworkStatusContext.Provider>
  );
};

export const useNetworkStatus = (): NetworkStatusContextProps => {
  const context = useContext(NetworkStatusContext);

  if (!context) {
    throw new Error("Error while trying to detect network status.");
  }
  return context;
};

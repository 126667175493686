import { FC } from "react";

const Header: FC = (): JSX.Element => {
    return (
        <div className="mb-6">
            <div className="divider divider-primary"><img src="/assets/img/branding/salute_africa_logo.webp" className="w-1/4 max-w-24"/></div>
        </div>
    );
}

export default Header;
import { TRIP } from "../routes/routes";
import { TripCountry, TripDestination, TripToJoin } from "../types/types";

export const GetTripToJoin = async () => {
    const tripGuid = localStorage.getItem('TripToJoin');

    let trip: any = null;

    try {
        await fetch(TRIP.GET.JOIN_TRIP + "/" + tripGuid, {
            method: "GET",
            headers: {
                Accept: "*/*",
            },
        })
        .then(async response => response.json())
        .then(async data => {
            trip = data;
        });
    } catch (error) {
        console.error("Unable to find trip." + '\n' + error);
    }

    return trip;
}

export const JoinSelectedTrip = async () => {
    const tripGuid = localStorage.getItem('TripToJoin');
    const userGuid = localStorage.getItem('UserGuid');

    let result = null;

    try {
        await fetch(TRIP.POST.JOIN_TRIP + "/" +  userGuid + "/" + tripGuid, {
            method: "POST",
            headers: {
                Accept: "*/*",
            },
        })
        .then(async response => response.json())
        .then(async data => {
            result = data;
        });
    } catch (error) {
        console.error("Unable to join trip." + '\n' + error);
    }

    return result;
}

export const FetchTripCache = async () => {
    const cachedTrip = await caches.open('trip');
    const tripResponse = await cachedTrip.match('/trip');

    if (tripResponse)
    {
        let data = await tripResponse?.json();
        let response = await data.response;
        
        return response;
    } else {
        return null;
    }
}

export const FetchTrip = async (userGuid: string) => {
    try {
        let trip = null;
        await fetch(TRIP.GET.GET_TRIP + "/" + userGuid, {
            method: "GET",
            headers: {
                Accept: "*/*",
            },
        })
        .then(async (response) => {
            const cache = await caches.open("trip");
            await cache.put('trip', response);
            return response;
        })
        .then(async data => {
            trip = await FetchTripCache();
        });

        return trip;
    } catch (error) {
        console.error("Unable to fetch trip." + '\n' + error);
    }
}

export const GetTrip = async (isOnline: boolean) => {
    const userGuid = localStorage.getItem('UserGuid');

    let trip = null;

    if (isOnline && userGuid) {
        trip = await FetchTrip(userGuid);
    } else {
        trip = await FetchTripCache();
    }

    return trip;
}

export const GetCurrentDestination = (countries: Array<TripCountry>, day: number | null) => {
    let currentDestination: TripDestination | null = null;
    if (day !== null) {
        for (let i = 0; i < countries.length; i++) {
            for (let j = 0; j < countries[i].tripDestinations.length; j++) {
                let selectedDestination = countries[i].tripDestinations[j];
                
                if (selectedDestination.startDay <= day && day <= selectedDestination.endDay)
                {
                    currentDestination = {
                        id: selectedDestination.id,
                        tripCountryId: selectedDestination.tripCountryId,
                        city: selectedDestination.city,
                        country: countries[i].country,
                        startDay: selectedDestination.startDay,
                        endDay: selectedDestination.endDay,
                        images: selectedDestination.images,
                        tripAccommodation: selectedDestination.tripAccommodation,
                        tripActivities: selectedDestination.tripActivities
                    }
                    return currentDestination;
                }
            };
        };
    }
}

// TODO add GetNextDestination for when there is no current destination

export const GetCurrentDay = (startDate: Date | null, endDate: Date | null) => {
    let today = new Date();

    if (startDate && endDate) {
        let amountOfDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

        if (startDate.toDateString() === today.toDateString())
        {
            return 1;
        } else if (endDate.toDateString() === today.toDateString())
        {
            return amountOfDays;
        } else if (startDate < today && today < endDate)
        {
            let currentDay = ((today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
            return (Math.round(currentDay) + 1);
        }
    }
    return null;
}

export const GetDestinationForDate = (countries: Array<TripCountry>, day: number | null): TripDestination | null => {
    let destination: TripDestination | null = null;

    if (day !== null) {
        for (let i = 0; i < countries.length; i++) {
            for (let j = 0; j < countries[i].tripDestinations.length; j++)
            {
                let destination = countries[i].tripDestinations[j];

                if (destination.startDay <= day && day <= destination.endDay)
                {
                    let currentDestination : TripDestination = {
                        id: destination.id,
                        tripCountryId: destination.tripCountryId,
                        city: destination.city,
                        country: countries[i].country,
                        startDay: destination.startDay,
                        endDay: destination.endDay,
                        images: destination.images,
                        tripAccommodation: destination.tripAccommodation,
                        tripActivities: destination.tripActivities
                    }

                    destination = currentDestination;
                    return destination;
                }
            }
        };
    }

    return destination
}
import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import {
  NetworkStatusProvider,
  useNetworkStatus,
} from "./Context/NetworkStatusContext";
import refreshToken from "./Utitlity/refreshTokenService";
import JoinTrip from "./pages/Auth/JoinTrip";
import { InstallPrompt } from "./components/InstallPrompt";

// Pages
const Welcome = lazy(() => import("./pages/Auth/Welcome"));
const SignIn = lazy(() => import("./pages/Auth/SignIn"));
const SignUp = lazy(() => import("./pages/Auth/SignUp"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const Home = lazy(() => import("./pages/Home/Home"));
const Itinerary = lazy(() => import("./pages/Itinerary/Itinerary"));
const DetailedItinerary = lazy(
  () => import("./pages/Detailed Itinerary/DetailedItinerary")
);
const Country = lazy(() => import("./pages/Country/Country"));

const AppContent: React.FC = () => {
  const { isOnline } = useNetworkStatus();
  const navigate = useNavigate();

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const tripGuid = params.get('trip');

  if (tripGuid) {localStorage?.setItem("TripToJoin", tripGuid)}

  // Create a state variable to track if user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage?.getItem("isAuthenticated");
  });

  let isRefreshing = false;

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isOnline && !isRefreshing) {
        isRefreshing = true;

        if (isAuthenticated === "true") {
          await refreshToken();
        } else {
          navigate("/");
        }

        isRefreshing = false;
      }
    };

    checkAuthentication();
  }, [isOnline]);

  return (
    <>
      <InstallPrompt />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/jointrip" element={<JoinTrip />} />
        <Route path="/home" element={<Home />} />
        <Route path="/itinerary" element={<Itinerary />} />
        <Route path="/detailed-itinerary" element={<DetailedItinerary />} />
        <Route path="/country" element={<Country />} />
      </Routes>
    </>
  );
};
// TODO add better loading state
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <NetworkStatusProvider>
          <AppContent />
        </NetworkStatusProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

import useIosInstallPrompt from '../Utitlity/hooks/iOS_installPwa';
import useWebInstallPrompt from '../Utitlity/hooks/installPwa';

export const InstallPrompt = () => {
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    if (!iosInstallPrompt && !webInstallPrompt) {
        return null;
    } else {
        debugger;
        // @ts-ignore
        document.getElementById('install-modal')?.showModal();
    }
    return (
        <dialog id="install-modal" className='modal modal-open'>
            <div className='modal-box'>
                <h2 className='font-palatino uppercase text-lg'>Install App</h2>
                
                {webInstallPrompt && (
                    <div>
                        <p>Install app and add to homescreen</p>
                        <div className='modal-action'>
                            <button className='btn btn-accent' onClick={handleWebInstallAccepted}>Install</button>
                            <button className='btn btn-primary' onClick={handleWebInstallDeclined}>Cancel</button>
                        </div>
                    </div>
                )}
                {iosInstallPrompt && (
                    <div>
                        <span> Tap 
                            <img
                                src="/assets/img/vectors/share-apple.svg"
                                style={{ margin: 'auto 6px auto 6px', display: 'inline-block' }}
                                className=""
                                alt="Add to homescreen"
                                width="24"
                            />
                            then &quot;Add to Home Screen&quot;
                        </span>
                        <div className='modal-action'>
                            <button className='btn btn-primary' onClick={handleIOSInstallDeclined}>Cancel</button>
                        </div>
                    </div>
                )}
            </div>
        </dialog>
    );
};
import { Jwt_TOKEN } from "../pages/Auth/Helpers/Auth";
import Auth, { RefreshTokenResponse } from "../pages/Auth/Helpers/Auth";
import secureLocalStorage from "react-secure-storage";
import { REFRESH_TOKEN } from "../pages/Auth/Helpers/Auth";
import { useNavigate } from "react-router-dom";

const refreshToken = async (): Promise<void> => {
  let tokenExpiry = localStorage?.getItem(Jwt_TOKEN.TOKEN_EXPIRY);
  let refreshToken = secureLocalStorage.getItem(REFRESH_TOKEN.REFRESH_TOKEN);

  const { RefreshToken } = new Auth();

  try {
    if (tokenExpiry && refreshToken) {
      let isTokenExpired: boolean = new Date() > new Date(tokenExpiry);

      if (isTokenExpired) {
        let response = await RefreshToken(refreshToken.toString());
        if (response) {
          localStorage?.setItem("isAuthenticated", JSON.stringify(true));
        } else {
          localStorage?.setItem("isAuthenticated", JSON.stringify(false));
        }
      }
    } else {
      localStorage?.setItem("isAuthenticated", JSON.stringify(false));
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    localStorage?.setItem("isAuthenticated", JSON.stringify(false));
  }
};

export default refreshToken;
